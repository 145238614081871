var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((['educator', 'admin']),function(role){return [_c('div',{key:role,staticClass:"card comment mt-3"},[_c('div',{staticClass:"card-content"},[_c('p',{staticClass:"title is-5 is-capitalized"},[_vm._v(_vm._s(role)+"'s Comment")]),_c('div',{staticClass:"content"},[(
              _vm.student.resultComments &&
              _vm.student.resultComments[(role + "Comment")]
            )?[_c('p',[_vm._v(" "+_vm._s(_vm.student.resultComments[(role + "Comment")].description)+" ")]),_c('div',{staticClass:"card-footer-item is-flex is-justify-content-end"},[_c('button',{staticClass:"button is-white has-text-success",staticStyle:{"font-size":"1.5em"},attrs:{"disabled":_vm.user.role !== role},on:{"click":function($event){return _vm.$emit(
                    'add-comment',
                    _vm.student.resultComments.id,
                    _vm.student.resultComments[(role + "Comment")].description
                  )}}},[_c('span',{staticClass:"icon"},[_c('fa-icon',{attrs:{"icon":"edit","size":"lg"}})],1)])])]:[_c('p',[_vm._v("No Comment")]),_c('div',{staticClass:"card-footer-item is-flex is-justify-content-end"},[_c('button',{staticClass:"button is-white has-text-info",staticStyle:{"font-size":"1.5em"},attrs:{"disabled":_vm.user.role !== role},on:{"click":function($event){return _vm.$emit('add-comment', null, null)}}},[_c('span',{staticClass:"icon"},[_c('fa-icon',{attrs:{"icon":"plus-circle","size":"lg"}})],1)])])]],2)])])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }