<template>
  <div id="result-comments">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-centered is-multiline">
        <div class="column is-8">
          <b-field label="Term">
            <b-select
              id="term-id"
              v-model="termId"
              placeholder="Select a option"
              expanded
            >
              <option v-for="term in terms" :key="term.id" :value="term.id">
                {{ term.sessionTitle }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-8">
          <p class="subtitle is-6">Student's Comments</p>
          <template v-if="student">
            <section class="has-background-white-ter" style="padding: 10px">
              <div class="columns">
                <div class="column">
                  <div class="media">
                    <div class="media-left">
                      <figure class="image is-48x48">
                        <img
                          class="is-rounded"
                          :src="student.imageUrl"
                          alt="avatar"
                          v-if="student.imageUrl"
                        />
                        <img
                          class="is-rounded"
                          src="@/assets/images/default_avatar.png"
                          alt="avatar"
                          v-else
                        />
                      </figure>
                    </div>
                    <div class="media-content">
                      <p class="title is-4" style="padding-top: 8px">
                        {{ student.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <role-comment
                :student="student"
                :user="user"
                @add-comment="addComment"
              />
            </section>
          </template>
          <section class="section" v-else>
            <div class="content has-text-grey has-text-centered">
              <p>
                <fa-icon icon="info-circle" class="fa-5x" />
              </p>
              <p>Select a school class to fetch students list</p>
            </div>
          </section>
        </div>
      </div>
    </template>
    <app-modal
      id="result-comments"
      :context="context"
      @add="add"
      @update="update"
      :show-modal.sync="isModalOpen"
    >
      <template v-slot:add>
        <b-field label="Description">
          <b-select
            id="description"
            v-model="comment.description"
            placeholder="Select a option"
            expanded
          >
            <option v-for="comment in comments" :key="comment.id">
              {{ comment.description }}
            </option>
          </b-select>
        </b-field>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser, fetchTerm } from '@/assets/js/app_info.js'
import TERMS from '../../../graphql/term/Terms.gql'
import COMMENTS from '../../../graphql/comment/Comments.gql'
import { crudNotification } from '../../assets/js/notification'
import RoleComment from '../../components/RoleComment.vue'

export default {
  name: 'result_comments',
  data() {
    return {
      pageTitle: 'Previous Result Comments',
      schoolId: null,
      student: null,
      icon_type: 'fas',
      icon_name: 'comment-alt',
      context: 'add',
      comment: {
        id: null,
        description: null,
      },
      comments: [],
      school_class: null,
      school_classes: [],
      user: null,
      terms: [],
      termId: '',
      adminId: null,
      adminCommentId: null,
      educatorId: null,
      educatorCommentId: null,
      isModalOpen: false,
    }
  },
  apollo: {
    terms: {
      query: TERMS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
    comments: {
      query: COMMENTS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    termId(value) {
      this.fetchResultComments()
    },
    student(data) {
      this.$store.commit('setSubMenus', [
        {
          name: 'Result Info',
          route: `/school/${this.schoolId}/school_class/${data.schoolClassId}/result_info/${data.id}/term_id/${this.termId}`,
        },
      ])
    },
    'comment.description'(newValue) {
      if (!newValue) return
      if (this.comments.length === 0) return

      this.comment.id = this.comments.filter(
        (comment) => comment.description === newValue
      )[0].id
    },
  },
  methods: {
    addComment(id, description) {
      if (this.student.resultComments) {
        this.comment.id = id
        this.comment.description = description
        this.context = 'update'
        this.isModalOpen = true
      } else {
        this.comment.id = id
        this.comment.description = description
        this.context = 'add'
        this.isModalOpen = true
      }
    },
    setIds() {
      if (this.user.role === 'admin') {
        this.adminId = parseInt(this.user.context)
        this.adminCommentId = parseInt(this.comment.id)
        if (!this.student.resultComments) return
        if (this.student.resultComments.educatorComment) {
          this.educatorId = parseInt(this.student.resultComments.educatorId)
          this.educatorCommentId = parseInt(
            this.student.resultComments.educatorComment.id
          )
        }
      } else {
        this.educatorId = parseInt(this.user.context)
        this.educatorCommentId = parseInt(this.comment.id)
        if (!this.student.resultComments) return
        if (this.student.resultComments.adminComment) {
          this.adminId = parseInt(this.student.resultComments.adminId)
          this.adminCommentId = parseInt(
            this.student.resultComments.adminComment.id
          )
        }
      }
    },
    add() {
      this.setIds()

      // Add
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateAteResultComment(
              $termId: Int!
              $studentId: Int!
              $adminId: Int
              $adminCommentId: Int
              $educatorId: Int
              $educatorCommentId: Int
            ) {
              createAteResultComment(
                input: {
                  termId: $termId
                  studentId: $studentId
                  adminId: $adminId
                  adminCommentId: $adminCommentId
                  educatorId: $educatorId
                  educatorCommentId: $educatorCommentId
                }
              ) {
                ateResultComment {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            termId: this.termId,
            studentId: parseInt(this.student.id),
            adminId: this.adminId,
            adminCommentId: this.adminCommentId,
            educatorId: this.educatorId,
            educatorCommentId: this.educatorCommentId,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.createAteResultComment.errors,
            'Successfully created.'
          ).then(() => {
            this.$apollo.queries.student.refetch()
            this.isModalOpen = false
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    update() {
      this.setIds()

      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateAteResultComment(
              $id: Int!
              $adminId: Int
              $adminCommentId: Int
              $educatorId: Int
              $educatorCommentId: Int
            ) {
              updateAteResultComment(
                input: {
                  id: $id
                  adminId: $adminId
                  adminCommentId: $adminCommentId
                  educatorId: $educatorId
                  educatorCommentId: $educatorCommentId
                }
              ) {
                ateResultComment {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.student.resultComments.id),
            adminId: this.adminId,
            adminCommentId: this.adminCommentId,
            educatorId: this.educatorId,
            educatorCommentId: this.educatorCommentId,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateAteResultComment.errors,
            'Successfully updated.'
          ).then(() => {
            this.$apollo.queries.student.refetch()
            this.isModalOpen = false
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    fetchResultComments() {
      this.$apollo.addSmartQuery('student', {
        query: gql`
          query StudentTermQuery($student_id: ID!, $term_id: ID!) {
            studentTerm(studentId: $student_id, termId: $term_id) {
              id
              name
              schoolClassId
              ateMarksAverageScore
              resultComments {
                id
                educatorId
                educatorComment {
                  id
                  description
                }
                adminId
                adminComment {
                  id
                  description
                }
              }
            }
          }
        `,
        variables: {
          student_id: this.studentId,
          term_id: this.termId,
        },
        fetchPolicy: 'cache-and-network',
        update: (data) => data.studentTerm,
        error(error) {
          this.$buefy.notification.open({
            duration: 5000,
            message: error.message,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
          })
        },
      })
    },
  },
  components: {
    RoleComment,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.studentId = parseInt(this.$route.params.student_id)
    this.termId = parseInt(this.$route.params.term_id)

    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id
    })
  },
}
</script>
