<template>
  <div>
    <template v-for="role in ['educator', 'admin']">
      <div class="card comment mt-3" :key="role">
        <div class="card-content">
          <p class="title is-5 is-capitalized">{{ role }}'s Comment</p>
          <div class="content">
            <template
              v-if="
                student.resultComments &&
                student.resultComments[`${role}Comment`]
              "
            >
              <p>
                {{ student.resultComments[`${role}Comment`].description }}
              </p>
              <div class="card-footer-item is-flex is-justify-content-end">
                <button
                  class="button is-white has-text-success"
                  style="font-size: 1.5em"
                  @click="
                    $emit(
                      'add-comment',
                      student.resultComments.id,
                      student.resultComments[`${role}Comment`].description
                    )
                  "
                  :disabled="user.role !== role"
                >
                  <span class="icon">
                    <fa-icon icon="edit" size="lg" />
                  </span>
                </button>
              </div>
            </template>
            <template v-else>
              <p>No Comment</p>
              <div class="card-footer-item is-flex is-justify-content-end">
                <button
                  class="button is-white has-text-info"
                  style="font-size: 1.5em"
                  @click="$emit('add-comment', null, null)"
                  :disabled="user.role !== role"
                >
                  <span class="icon">
                    <fa-icon icon="plus-circle" size="lg" />
                  </span>
                </button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    student: Object,
    user: Object,
  },
}
</script>
